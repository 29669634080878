<template>
  <b-container fluid class="p-5">
    <div>
      <h3 class="text-info">مصرفنا</h3>
      <div v-html="about"></div>
    </div>

    <div>
      <h3 class="text-info mt-5">الشروط والأحكام</h3>
      <div v-html="conditions"></div>
    </div>
  </b-container>
</template>

<script>
export default {
  computed: {
    about() {
      return this.$store.state.website.about;
    },
    conditions() {
      return this.$store.state.website.conditions;
    },
  },
  mounted() {
    this.$store.dispatch("website/about");
    this.$store.dispatch("website/conditions");
  },
};
</script>

<style></style>
